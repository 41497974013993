import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";
import store from "@/store/modules/usuario.store.js";

export async function generatePdf(ordem_servico_id) {
  const ordem_servico = await fetchOrdemServico(ordem_servico_id);
  const empresa = await fetchEmpresa(store.state.empresa.id);

  const produtos = ordem_servico.produtos;
  let texto_produtos = "";
  produtos.forEach((produto, index) => {
    if (!produto.kit) {
      texto_produtos += produto.qtde + " " + produto.descricao + ", ";
    }
  });
  texto_produtos =
    texto_produtos.slice(0, -2) + " TODOS EN PERFECTO ESTADO DE CONSERVACION.";

  return new Promise((resolve) => {
    let content = [
      {
        margin: [25, 25, 25, 25],
        stack: [
          {
            text: [
              {
                text: `DECLARACION DE RECIBIMIENTO DEL PRODUCTO, CERTIFICADO DE GARANTIA Y MANUAL.`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              `Declaro que, en esta fecha, la empresa POTABLE S.A. me ha entregado los productos abajo descriptos, con todos sus accesorios en perfecto estado de conservación y funcionamiento: `,
              `\n\n`,

              texto_produtos,

              `\n`,
              {
                text: `OBS:`,
                fontSize: 12,
                bold: true,
              },
              `  EL CLIENTE SE COMPROMETE EN REALIZAR LA CONFECCION DEL CONTRAPISO INMEDIATAMENTE DESPUES DEL TERMINO DE LA INSTALACION DE LA PISCINA PARA EVITAR QUE EN CASOS DE LLUVIAS FUERTES O ALAGAMIENTOS VENGAN A DANIFICAR LA PISCINA, OCASIONANDO LA PERDIDA TOTAL DE LA GARANTIA DE FABRICA, TAMBIEN, SE RESPONSABILIZA POR LA CONTRATACION DE LA MANO DE OBRA CUALIFICADA PARA ESTE FIN, Y LA CORRESPONDIENTE ORIENTACION AL OBRERO PARA QUE RESPETE LA DETERMINACION TECNICA QUE CONTIENE EN EL CERTIFICADO DE GARANTIA, EN ESPECIAL LO QUE `,

              {
                text: `NIVEL MINIMO DE 3%  DE DESNIVEL  DE LAS CALZADAS ALREDEDOR DE LA PISCINA Y CASA DE MAQUINAS`,
                fontSize: 12,
                bold: true,
              },
              ` PARA EVITAR QUE EL AGUA DE LAS LLUVIAS O DE LA LIMPIEZA DE LAS CALZADAS ENTRE DENTRO DE LAS MISMAS, PRINCIPALMENTE EN LA CASA DE MAQUINAS, MOJANDO LOS MATERIALES ELECTRICOS Y ASI DEJANDO SIN VALOR SU GARANTIA, ESTANDO LA EMPRESA LIBRE DE CUALQUIER RESPONSABILIDAD POR DAÑOS OCASIONADOS A LA PISCINA O SUS EQUIPOS POR TERCERAS PERSONAS Y/O POR EL SERVICIO PRESTADO POR TERCEROS, ASI COMO ESTIPULADO EN EL CONTRATO DE COMPRAVENTA DE LA PISCINA EN CLAUSULA DECIMA CUARTA.`,
              `\n\n`,
              `Declaro también que me han entregado los documentos debajo descritos, referentes a los productos arriba mencionados:`,
              `\n\n`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0-  Certifcado de garantía de la piscina N.º  ${ordem_servico.numero_garantia} y manual de la piscina modelo/marca PISCINA con la relación de las situaciones que se excluyen de la garantía.`,
              `\n`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0- Manual de los productos listados arriba.`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0- Factura legal de compra de los productos.`,
              `\n\n`,
              `En pruebe de conformidad firmo la presente, en Ciudad del Este, ____  de ________________ de __________.`,
            ],
            fontSize: 11,
            alignment: `justify`,
          },

          {
            margin: [0, 50, 0, 0],
            columns: [
              // {
              //   text: [
              //     `………………………………………………………\n`,
              //     `${empresa.nome}\n`,
              //     `RUC: ${empresa.documento}\n`,
              //     `VENDEDOR`,
              //   ],
              //   alignment: `center`,
              //   fontSize: 11,
              //   bold: true,
              // },
              {
                text: [
                  `………………………………………………\n`,
                  `${ordem_servico.cliente_nome}\n`,
                  `RUC: ${ordem_servico.cliente_doc}\n`,
                  `COMPRADOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
            ],
          },
        ],
      },
    ];

    let docDefinition = {
      pageSize: `A4`,
      pageMargins: [15, 15, 15, 15],
      pageOrientation: `portrait`, //`portrait`
      info: {
        title: `Document`,
      },
      defaultStyle: {
        font: `Roboto`,
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: `Roboto-Regular.ttf`,
        bold: `Roboto-Medium.ttf`,
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
