import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import store from "@/store/index.js";
import { format, parseISO } from "date-fns";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";
import { clienteInfosVenda } from "@/api/clientes/clientes.js";

export async function generatePdf(empresa, ordem_servico_id, tipo="normal") {
  const ordem_servico = await fetchOrdemServico(ordem_servico_id);
  const venda = await fetchOrdemServico(ordem_servico_id);
  const cliente = await clienteInfosVenda(ordem_servico.cliente_id);
  let instalador = "";
  if (tipo=="normal") {
    instalador = ordem_servico.produtos.find((produto) => {
      return produto.fornecedor_id;
    });
    instalador = instalador != undefined ? instalador.fornecedor_nome : "";
  } else {
    ordem_servico.prestadores.forEach((prestador) => {
      instalador += prestador.fornecedor_nome + ", ";
    });
    instalador = instalador.slice(0, -2);
  }

  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.usuario.name;

    let logo = {
      image: empresa.logo64,
      width: 100,
      alignment: "center",
      margin: [0, 0, 0, 25],
    };

    let titulo = [
      {
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: "CHECK LIST INSTALACION DE PISCINAS",
                    fontSize: 12,
                    bold: true,
                    alignment: "center",
                  },
                ],
              },
            ],
          ],
        },
      },
    ];

    let dados = [
      {
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text:
                      i18n.tc("global.cliente").toUpperCase() +
                      ": " +
                      cliente.nome +
                      " - " +
                      ordem_servico.cliente_doc,
                    fontSize: 8,
                  },
                  {
                    text: "Direccion: " + cliente.endereco_principal,
                    fontSize: 8,
                  },
                  {
                    text:
                      "Fecha Inicio: " +
                      format(
                        parseISO(ordem_servico.previsao_inicio),
                        "dd/MM/yyyy"
                      ) +
                      "     Fecha fim: " +
                      format(
                        parseISO(ordem_servico.previsao_fim),
                        "dd/MM/yyyy"
                      ),
                    fontSize: 8,
                  },
                  {
                    text: "Instalador: " + instalador,
                    fontSize: 8,
                  },
                ],
              },
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: `${i18n
                      .tc("global.ordensServicos")
                      .toUpperCase()} Nº.: ${ordem_servico.id}`,
                    alignment: "right",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text: format(
                      parseISO(ordem_servico.data_criada),
                      "dd/MM/yyyy"
                    ),
                    alignment: "right",
                    fontSize: 8,
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          // vLineWidth: function(i, node) {
          //   return i === 0 || i === node.table.widths.length ? 0 : 1;
          // },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      },
    ];

    let tabela_items = {
      table: {
        widths: ["*", "*", 85, 85, 85],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: i18n.tc("global.descricao").toUpperCase(),
              fontSize: 8,
              fillColor: "#CFD8DC",
              alignment: "center",
            },
            {
              text: "Conforme",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
            },
            {
              text: "Al realizar hoyo",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
            },

            {
              text: "Carga a nivel medio",
              fontSize: 8,
              alignment: "center",
              fillColor: "#CFD8DC",
            },
            {
              text: "Carga completa (final de instalacion)",
              fontSize: 8,
              alignment: "center",
              fillColor: "#CFD8DC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Nivel de la piscina",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Limpieza de caja de maquinas",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Buen funcionamiento del motor",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Buen funcionamiento de filtros",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Buen funcionamiento de llaves",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Buen funcionamiento de luces (si corresponde)",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Verificación de los accesorios adicionales durante la instalacion en caso que corresponda ",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
          [
            {
              margin: [5, 0, 0, 15],
              text: "Finalizacion conforme a tiempo estimado (1 semana) ",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },

            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
            {
              text: "",
              fontSize: 8,
              fillColor: "#CCCCCC",
            },
          ],
        ],
      },
      layout: {
        // hLineWidth: function (i, node) {
        //   return i <= 1 || i === node.table.body.length ? 1 : 0;
        // },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let observacao = {
      margin: [0, 20, 0, 0],
      table: {
        widths: ["*"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              margin: [0, 0, 0, 50],
              text: i18n.tc("global.observacao").toUpperCase(),
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        // hLineWidth: function (i, node) {
        //   return i <= 1 || i === node.table.body.length ? 1 : 0;
        // },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let titulo_ph = [
      {
        margin: [0, 0, 0, 5],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],

                stack: [
                  {
                    text: "CONTROL DE PARAMETROS QUIMICOS",
                    fontSize: 12,
                    bold: true,
                    alignment: "center",
                  },
                ],
              },
            ],
          ],
        },
      },
    ];
    let niveis_ph = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: i18n.tc("global.parametros").toUpperCase(),
              alignment: "center",
              fontSize: 8,
            },
            {
              text: i18n.tc("global.valorReferencia").toUpperCase(),
              alignment: "center",
              fontSize: 8,
            },
            {
              text: i18n.tc("global.resultado").toUpperCase(),
              alignment: "center",
              fontSize: 8,
            },
            {
              text: i18n.tc("global.correcao").toUpperCase(),
              alignment: "center",
              fontSize: 8,
            },
          ],
          [
            {
              text: "pH",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "7,2 - 7,8",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
          ],
          [
            {
              text: "Alcalinidad",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "80 - 120 ppm",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
          ],
          [
            {
              text: "Cloro",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "1 - 3 ppm",
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
            {
              text: "",
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        // hLineWidth: function (i, node) {
        //   return i <= 1 || i === node.table.body.length ? 1 : 0;
        // },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let obs_ph = [
      {
        margin: [0, 0, 0, 5],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: "Obs: El método utilizado para la obtención de estos resultados es por Colorimetría (Kit de Test) Los valores de referencia utilizados estan declarados en el instructivo de uso del kit de Test empleado Marca Neoclor ",
                    fontSize: 8,
                    bold: true,
                  },
                ],
              },
            ],
          ],
        },
      },
    ];

    let assinatura = [
      {
        columns: [
          {
            text:
              "__________________________________________ \n" +
              instalador,
            fontSize: 7,
            alignment: "center",
            margin: [0, 0, 0, 20],
            border: [true, false, false, false],
          },

          {
            text: "",
            margin: [0, 0, 0, 20],
            border: [false, false, false, false],
          },
          {
            text:
              "__________________________________________ \n" +
              cliente.nome,
            fontSize: 7,
            alignment: "center",
            margin: [0, 0, 0, 20],
            border: [false, false, true, false],
          },
        ]
      }

    ];

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.2,
      },
      content: [
        empresa.logo64 ? logo : "",
        titulo,
        ...dados,
        tabela_items,
        observacao,
        titulo_ph,
        niveis_ph,
        obs_ph,
        assinatura,
      ],
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `${nome} as ${now}`,
                fontSize: 7,
                margin: [15, 0, 0, 0],
                width: "25%",
              },
              {
                text: "SOFTPAR - SOFTWARE A MEDIDA DO SEU NEGÓCIO",
                fontSize: 7,
                alignment: "center",
                width: "*",
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 15, 0],
                alignment: "right",
                fontSize: 7,
                width: "25%",
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
